import React, { useEffect, useState } from "react"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import SEO from "../../components/seo"
import triple from "../../api/triple"
import FormCareer from "../../components/careercomponents/careerForm/careerform"
import {
  CareerSection,
  CareerWrapper,
  DropCareer,
  DropHeadingButton,
  DropHeadingTitle,
  FormWrapper,
  ToggleButton,
  ToggleH2Styled,
} from "../../components/careercomponents/careerDroping/dropStyle"
import {
  FacebookIcon,
  FacebookShare,
  LinkdinIcon,
  LinkedinShare,
  SharedWrapperCol,
  ShareLabel,
} from "../../components/careercomponents/careerForm/formStyle"

const CareerDropWrapper = ({
                             setCareerdata,
                             careerdata,
                             singleCareer,
                             seotitle,
                             seodescription,
                             locale,
                             formlangtext,
                             pageContext,
                             careerForm,
                           }) => {
  let [urlShared, setUrlShared] = useState()
  let [single, setSingle] = useState()
  const lang = pageContext.locale

  useEffect(() => {
    triple.get("/api/join_our_team")
      .then(res => {
        makeOpened(res)
      })
      .catch(err => console.log(err))
  }, [])

  const getSharedUrl = lng => {
    if (single || singleCareer) {
      if (lng === "en") {
        return `http://triple-c.am/en/career/#${single?.id || singleCareer?.id}/`
      } else {
        return `http://triple-c.am/arm/career/#${single?.id || singleCareer?.id}/`
      }
    }
  }
  const hookComponent = () => {
    setUrlShared(getSharedUrl(lang))
  }

  function makeOpened (res){
    for (let i = 0; i < res.data.data.length; i++){
      if(res.data.data.length === 1 || location.hash.includes(res.data.data[i].id)){
        res.data.data[i]['open'] = true;
        setSingle(res.data.data[i])
        window.scrollTo(0, res.data.data[i].offsetTop)
      }
      else{
        res.data.data[i]['open'] = false;
      }
    }
    setCareerdata(res.data.data)
  }

  useEffect(() => {
    hookComponent()
  }, [lang])

  const toggle = (current) => {
    const data = careerdata.map(d =>
      d.id === current.id && d.open === false
        ? { ...d, open: true }
        : d.id !== current.id && d.open === true
        ? { ...d, open: false }
        : { ...d, open: false }
    )
    setCareerdata(data)
  }
  return (
    <>
      <SEO
        title={single && single[`title_${lang}`]}
        description={single && single[`description_${lang}`]}
        pageContext={pageContext}
      />
      <DropCareer>
        <DropHeadingTitle>
          <ToggleH2Styled>{singleCareer && singleCareer[`title_${lang}`]}</ToggleH2Styled>
          <DropHeadingButton>
            <ToggleButton
              className={singleCareer?.open ? "whiteButton" : "blackButton"}
              block onClick={() => toggle(singleCareer)}
            >
              {singleCareer?.open ? <UpOutlined /> : <DownOutlined />}
            </ToggleButton>
          </DropHeadingButton>
        </DropHeadingTitle>
      </DropCareer>
      <FormWrapper
        className={singleCareer?.open ? "openTextCol" : ""}
        xxl={{ span: 19, offset: 5 }}
        xl={{ span: 22, offset: 3 }}
        lg={{ span: 22, offset: 2 }}
        md={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 0 }}
        xs={{ span: 22, offset: 0 }}
      >
        <CareerWrapper>
          <CareerSection>{singleCareer && singleCareer[`description_${lang}`]}</CareerSection>
        </CareerWrapper>
        <div>
          <FormCareer
            data={single && single || singleCareer && singleCareer}
            seotitle={seotitle && seotitle}
            formlangtext={formlangtext}
            seodescription={seodescription && seodescription}
            locale={locale && locale}
            lang={lang}
          />
          <SharedWrapperCol className="career">
            <ShareLabel>{careerForm && careerForm.share}</ShareLabel>
            <FacebookShare
              url={urlShared}
              children={<FacebookIcon />}
            />
            <LinkedinShare
              url={urlShared}
              children={<LinkdinIcon />}
            />
          </SharedWrapperCol>
        </div>
      </FormWrapper>
    </>
  )
}
export default CareerDropWrapper